type ContentComponentType =
  | 'none'
  | 'saveClinic'
  | 'saveMenu'
  | 'complete'
  | 'registerComplete'
export const useToastStore = defineStore('toast-store', () => {
  const isShow = ref(false)
  const content = ref('')
  const contentComponentType = ref('none' as ContentComponentType)
  const setContent = (contentArg: string) => {
    content.value = contentArg
  }
  const setContentComponentType = (
    contentComoponentTypeArg: ContentComponentType
  ) => {
    contentComponentType.value = contentComoponentTypeArg
  }
  const show = () => {
    isShow.value = true
  }
  const hide = () => {
    isShow.value = false
  }
  return {
    isShow,
    content,
    contentComponentType,
    setContent,
    setContentComponentType,
    show,
    hide,
  }
})
